const config = {
  rootProjectId: "root",
  uiBucketName: "fiba.c.retter.io",
  appUrl: "https://api.fiba.retter.io/",
  cosUrl: "api.fiba.retter.io",
  version: "2.0.35",
  captchaKey: "6Lc-I0spAAAAAN7XSwvXvf76bbdJowFzr7aSAhpp",
  stage: "PROD"
}
export default config

